import React from 'react';
import cover from './cover.jpg';
import spotify from './spotify.svg';
import apple from './apple.svg';
import youtube from './youtube.png';
import insta from './insta.png';
import venmo from './venmo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      {/* <img src={bg} className="App-bg" alt="bg" /> */}
      <div className="content">
        <div className="side-by-side">
          <div className="cover"><img src={cover} className="cover-img" alt="disc" /></div>
          <div>
            <h1>Rachel Lee Roberts</h1>
            <iframe src="https://open.spotify.com/embed/playlist/5MelAlYgQN6g0n87nSfsiE" width="300" height="200" frameBorder="0" allowTransparency="true" allow="encrypted-media"></iframe>
            <div className="body">
              <p>Available to perform in Northern California. Contact management for interviews & bookings <a href="mailto:rachelleerobertsmusic@gmail.com">rachelleerobertsmusic@gmail.com.</a></p>
            </div>
            <a className="button" target="_blank" href="https://forms.gle/TFfhAT6WZfNY2mdXA">Get notified of future releases</a>
          </div>
        </div>
        <div className="footer">
          <div className="services">
            <a target="_blank" href="https://venmo.com/u/raromusic">
              <img src={venmo} className="venmo" alt="Venmo"/>
            </a>
            <a target="_blank" href="https://open.spotify.com/album/6bT3RFwklkfLgghmDLGkle?si=0UYeBFAMQoC7JbrNYFxHjQ">
              <img src={spotify} className="spotify" alt="Spotify logo"/>
            </a>
            <a target="_blank" href="https://music.apple.com/us/album/aimed-at-me-single/1521104056">
              <img src={apple} alt="Apple Music logo"/>
            </a>
            <a target="_blank" href="https://www.youtube.com/watch?v=kcOuWOl-kJ4">
              <img src={youtube} className="youtube" alt="YouTube"/>
            </a>
            <a target="_blank" href="https://www.instagram.com/rachelleerobertsmusic/">
              <img src={insta} className="insta" alt="Instagram"/>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
